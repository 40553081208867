import './Footer.scss';

import React, { ChangeEvent, MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';

import ArrowRightIcon from '../../../general/assets/svg/ArrowRightIcon';
import CopyrightIcon from '../../../general/assets/svg/CopyrightIcon';
import DropDownProfileIcon from '../../../general/assets/svg/DropDownProfileIcon';
import FacebookLinkFooterIcon from '../../../general/assets/svg/FacebookLinkFooterIcon';
import InstagranFooterIcon from '../../../general/assets/svg/InstagranFooterIcon';
import LinkedInFooterIcon from '../../../general/assets/svg/LinkedInFooterIcon';
import LocationMarkerIcon from '../../../general/assets/svg/LocationMarkerIcon';
import LogoIcon from '../../../general/assets/svg/LogoIcon';
import MailIcon from '../../../general/assets/svg/MailIcon';
import PhoneIcon from '../../../general/assets/svg/PhoneIcon';
import ScrollToTopFooterIcon from '../../../general/assets/svg/ScrollToTopFooterIcon';
import TelegramLinkFooterIcon from '../../../general/assets/svg/TelegramLinkFooterIcon';
import ToTopArrowIcon from '../../../general/assets/svg/ToTopArrowIcon';
import TwitterFooterIcon from '../../../general/assets/svg/TwitterFooterIcon';
import WhatsUpFooterIcon from '../../../general/assets/svg/WhatsUpFooterIcon';
import Preloader, { preloaderStyles } from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import useRedirectToHelpCenter from '../../../general/hooks/useRedirectToHelpCenter';
import useRedirectToProducts from '../../../general/hooks/useRedirectToProducts';
import { useResize } from '../../../general/hooks/useResize';
import { queryKeys } from '../../../general/queryKeys';
import { routesClient } from '../../../general/routes';
import createClientService from '../../../general/services/client';
import createContactsService from '../../../general/services/contacts';
import createHelpCenterServiceService from '../../../general/services/helpCenter';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import createProductsService from '../../../general/services/products';
import { Contacts, ShopLink } from '../../../general/types/types';
import { regexp } from '../../../general/utils/Validations';
import { QuestionGroup } from '../../pages/help-center/faq-and-qa/FAQ';
import NewSubscriber from '../modals-window/NewSubscriber';

const Footer: React.FC = () => {
  const { data: retailCount } = useCustomQuery<ShopLink>(
    queryKeys.PRODUCT_CATEGORIES_RETAIL,
    () => createProductsService(clientInstance).getProductsCategories(),
    (data: any) => {
      const obj = {
        priceCategoryCount: data?.reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue?.retailProductsCount,
          0,
        ),
        machineCategoryCount: data.length === 1 ? data[0].url : null,
      };

      return obj;
    },
  );

  const { data: wholesaleCount } = useCustomQuery<ShopLink>(
    queryKeys.PRODUCT_CATEGORIES_WHOLESALE,
    () => createProductsService(clientInstance).getProductsCategories(),
    (data: any) => {
      const obj = {
        priceCategoryCount: data?.reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + currentValue?.wholesaleProductsCount,
          0,
        ),
        machineCategoryCount: data.length === 1 ? data[0].url : null,
      };

      return obj;
    },
  );

  const { data: faqs } = useCustomQuery<QuestionGroup[]>(queryKeys.FAQS, () =>
    createHelpCenterServiceService(clientInstance).getAllFAQ(),
  );

  const { data: qaes } = useCustomQuery<QuestionGroup[]>(queryKeys.QAES, () =>
    createHelpCenterServiceService(clientInstance).getAllFAQ(),
  );

  const { data: contacts } = useCustomQuery<Contacts>(queryKeys.COMPANY_CONTACTS, () =>
    createContactsService(clientInstance).getContacts(),
  );

  const { width } = useResize(); // Be careful, there may be a load on the application
  const [isPreloader, setIsPreloader] = useState(false);
  const [isSubscriber, setIsSubscriber] = useState(false);

  const setDefaultInput = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.style.border = '1px solid #25A8CF';
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const addNewSubscriber = async () => {
    const input = document.querySelector('.feedback-sections__form-input') as HTMLInputElement;
    setIsPreloader(true);
    if (regexp.email_regexp.test(input.value)) {
      await createClientService(clientInstance).postNewSubscriber({
        email: input.value,
      });
      input.value = '';
      setIsSubscriber(true);
    } else {
      input.style.border = '1px solid red';
    }
    setIsPreloader(false);
  };

  const toggleDropDown = (e: MouseEvent<HTMLDivElement>) => {
    const element = e.currentTarget.nextElementSibling as HTMLElement;
    if (element) {
      element.classList.toggle('drop_down_close');
    }
  };

  const redirectToProducts = useRedirectToProducts(retailCount, wholesaleCount);
  const redirectToHelpCenter = useRedirectToHelpCenter(qaes, faqs);

  return (
    <>
      {isSubscriber && <NewSubscriber isOpen={setIsSubscriber} />}
      <footer className="footer-section page-container">
        <div className="footer-section__feedback-sections">
          <div className="feedback-sections__content">
            <div className="feedback-sections__text">
              <h1 className="feedback-sections__text-h1 section-h1">GET THE ASICXCHANGE MEMO</h1>
              <p className="feedback-sections__text-p section-p">
                Read weekly insights and research from the cryptocurrency industry's best mining
                analysts.
              </p>
            </div>
            <div className="feedback-sections__form">
              <input
                onChange={setDefaultInput}
                type="email"
                placeholder="Email address"
                className="feedback-sections__form-input"
              />
              <button
                className="feedback-sections__form-btn section-btn-gradient"
                onClick={addNewSubscriber}
              >
                {isPreloader ? (
                  <div className="footer-preloader">
                    <Preloader style={preloaderStyles.DARK} />
                  </div>
                ) : (
                  <>
                    Subscribe
                    <ArrowRightIcon fill="white" />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="footer-section-content">
          <div className="footer-section-content__columns">
            <div className="section__column section__column__text">
              <div className="column-content">
                <div className="column-content__content">
                  <div className="content-logo">
                    <LogoIcon />
                    AsicXchange
                  </div>
                  <div className="content-text">
                    AsicXchange bitcoin-first company on a mission to support the decentralized
                    growth of hashrate and strengthen network security by helping more people learn,
                    explore and mine Bitcoin. Now Everyone Can Mine Bitcoin.
                  </div>
                </div>
                <div className="column-content__copyright">
                  <div className="column-content__copyright__div">
                    <div>
                      <CopyrightIcon />
                      <span>2024</span>
                    </div>
                    <div className="links">
                      <Link to={routesClient.userAgreement.path}>User Agreement</Link>
                      <Link to={routesClient.publicOffer.path}>Public Offer</Link>
                      <Link to={routesClient.cookiesPolicy.path}>Cookie Policy</Link>
                    </div>
                  </div>
                  {width <= 1024 && (
                    <div className="social-icon" onClick={scrollToTop}>
                      <ToTopArrowIcon />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="section__column section__column__contacts">
              <div className="column-contacts">
                <div className="drop_down" onClick={toggleDropDown}>
                  <h3>Contacts</h3>
                  <DropDownProfileIcon />
                </div>
                <div className="column-contacts__items">
                  <div className="column-contacts__item">
                    <div className="column-contacts__icon">
                      <LocationMarkerIcon fill="white" />
                    </div>
                    <div className="column-contacts__text">
                      <span>Address: </span>
                      <p className="column-contacts__text-p">
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(contacts?.addressWeb as string)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {contacts?.addressWeb}
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="column-contacts__item">
                    <div className="column-contacts__icon">
                      <PhoneIcon />
                    </div>
                    <div className="column-contacts__text">
                      <span>Phone: </span>
                      <p className="column-contacts__text-p text__p">
                        <a href={`tel:${contacts?.phoneWeb}`}>{contacts?.phoneWeb}</a>
                      </p>
                    </div>
                  </div>
                  <div className="column-contacts__item">
                    <div className="column-contacts__icon">
                      <MailIcon />
                    </div>
                    <div className="column-contacts__text">
                      <span>Email: </span>
                      <p className="column-contacts__text-p text__p">
                        <a href={`mailto:${contacts?.emailWeb}`}>{contacts?.emailWeb}</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section__column section__column__navigation">
              <div className="column-navigation">
                <div className="drop_down" onClick={toggleDropDown}>
                  <h3>Navigation</h3>
                  <DropDownProfileIcon />
                </div>
                <ul className="column-navigatin__list-links">
                  <li className="column-navigatin-link">
                    <Link to={routesClient.home.path}>Home</Link>
                  </li>
                  <li className="column-navigatin-link">
                    <Link to={redirectToProducts}>Products</Link>
                  </li>
                  <li className="column-navigatin-link">
                    <Link to={routesClient.blog.path}>Blog</Link>
                  </li>
                  <li className="column-navigatin-link">
                    <Link to={redirectToHelpCenter}>Help Center</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="section__column section__column__social">
              <div className="column-social">
                <div className="drop_down">
                  <h3>Social</h3>
                </div>
                <div className="column-social__icons">
                  <div className="column-social__icons__row">
                    <div className="social-icon">
                      <Link
                        to={contacts?.telegramLink || `${routesClient.stub.path}`}
                        target="_blank"
                      >
                        <TelegramLinkFooterIcon color="white" />
                      </Link>
                    </div>
                    <div className="social-icon">
                      <Link
                        to={contacts?.facebookLink || `${routesClient.stub.path}`}
                        target="_blank"
                      >
                        <FacebookLinkFooterIcon />
                      </Link>
                    </div>
                    <div className="social-icon">
                      <Link
                        to={contacts?.linkedinLink || `${routesClient.stub.path}`}
                        target="_blank"
                      >
                        <LinkedInFooterIcon />
                      </Link>
                    </div>
                  </div>
                  <div className="column-social__icons__row">
                    <div className="social-icon">
                      <Link
                        to={contacts?.instagramLink || `${routesClient.stub.path}`}
                        target="_blank"
                      >
                        <InstagranFooterIcon color="white" />
                      </Link>
                    </div>
                    <div className="social-icon">
                      <Link to={contacts?.xLink || `${routesClient.stub.path}`} target="_blank">
                        <TwitterFooterIcon color="white" />
                      </Link>
                    </div>
                    <div className="social-icon">
                      <Link
                        to={contacts?.whatsappLink || `${routesClient.stub.path}`}
                        target="_blank"
                      >
                        <WhatsUpFooterIcon />
                      </Link>
                    </div>
                  </div>
                  <div className="column-social__icons__row">
                    <div className="social-icon" onClick={scrollToTop}>
                      <ScrollToTopFooterIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
