import React, { useRef, useState } from 'react';

import PauseVideoIcon from '../../../general/assets/svg/PauseVideoIcon';
import ResumeVideoIcon from '../../../general/assets/svg/ResumeVideoIcon';
import Preloader from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../general/queryKeys';
import createHomePageService from '../../../general/services/homePage';
import { clientInstance } from '../../../general/services/main/axiosInstances';

//import { HomePageServices } from '../../services/HomePageServices';
interface VideoData {
  url: string;
}

const VideoBlock: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlay, setIsPlay] = useState<boolean>(true);

  const { data: video, isLoading: isLoadingVideo } = useCustomQuery<VideoData>(
    queryKeys.HOME_VIDEO,
    () => createHomePageService(clientInstance).getVideoLink(),
    undefined,
    undefined,
    {
      onSuccess: () => {
        if (videoRef.current) {
          videoRef.current.load();
          videoRef.current.muted = true;
          videoRef.current.play().catch((error) => {
            console.error('Error trying to play the video: ', error);
          });
        }
      },
    },
  );

  const videoPause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlay(false);
    }
  };

  const videoPlay = () => {
    if (videoRef.current) {
      // Убеждаемся, что звук отключен при воспроизведении
      videoRef.current.muted = true;
      videoRef.current.play();
      setIsPlay(true);
    }
  };

  return (
    <div className="video-block-container">
      {isLoadingVideo || !video ? (
        <>
          <Preloader />
        </>
      ) : (
        <>
          <div className="introduction-section__video-action">
            {isPlay ? (
              <div onClick={videoPause}>
                <PauseVideoIcon />
              </div>
            ) : (
              <div onClick={videoPlay}>
                <ResumeVideoIcon />
              </div>
            )}
          </div>
          {video.url && (
            <video
              ref={videoRef}
              controls={false}
              muted={true}
              playsInline={true}
              autoPlay={true}
              loop={true}
              src={video?.url}
              onClick={isPlay ? videoPause : videoPlay}
              onContextMenu={(e) => e.preventDefault()}
            />
          )}
        </>
      )}
    </div>
  );
};

export default VideoBlock;
